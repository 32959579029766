import page from 'page';

export default function() {
  firebase.auth().signOut().then(function() {
    // Sign-out successful.
    page.redirect('/');
  }).catch(function(error) {
    // An error happened.
    console.log(error);
  });
}
