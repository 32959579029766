import * as d3 from 'd3';
import * as topojson from 'topojson';

export default function() {

  let container = d3.select('#mappa-manicomi');

  let width = parseInt(container.style('width'));
  let height = parseInt(container.style('height'));

  let projection = d3.geoMercator()
    .center([12.5711509,43.2648295])
    .scale(width * 4.8)
    .translate([width/2, height/2]);
  
  let path = d3.geoPath()
    .projection(projection);

  var svg = container.append('svg')
      .attr('width', width)
      .attr('height', height);

  d3.tsv('/../data/manicomi.tsv', function(manicomi){

    manicomi.forEach( (d, index) => {
      d.latitude = + d.latitude.replace(/,/g, '.');
      d.longitude = + d.longitude.replace(/,/g, '.');
      d[0] = + d.longitude;
      d[1] = + d.latitude;
      d.id = index;
      d.projectionX = projection( [ d.longitude, d.latitude ])[0];
      d.projectionY = projection( [ d.longitude, d.latitude ])[1];
    });

    d3.json('/../data/ita-province.json', function(error, map) {

      svg.append('g')
        .attr('class', 'map__ita')
        .selectAll('.subunit')
        .data(topojson.feature(map, map.objects.sub).features)
        .enter().append('path')
        .attr('d', path)
        .attr('class', 'map__region');

      svg.append('g')
        .attr('class', 'map__circles')
        .selectAll('circle')
        .data(manicomi)
        .enter()
        .append('circle')
        .attr('class', 'map__point')
        .attr('data-point-id', (d, i) => i)
        .attr('cx', d => projection( [ d.longitude, d.latitude ])[0])
        .attr('cy', d => projection( [ d.longitude, d.latitude ])[1])
        .attr('r', 5);

      var voronoi = d3.voronoi()
        .x(function(d) { return projection( [ d.longitude, d.latitude ])[0]; })
        .y(function(d) { return projection( [ d.longitude, d.latitude ])[1]; })
        .extent([[-1, -1], [width + 1, height + 1]]);


      svg.append('g')
        .attr('class', 'map__voronoi')
        .selectAll('path')
        .data(voronoi(manicomi).polygons()) //Use vononoi() with your dataset inside
        .enter().append("path")
        .attr('d', function(d) { return d ? "M" + d.join("L") + "Z" : null; })
        .on('mouseover', mouseEnter)
        .style('fill', 'none')
        .style('stroke', '#000')
        .style('stroke-width', 0);

      
      function mouseEnter() {
        let d = this.__data__;

        let tooltip = d3.select('#tooltip');

        tooltip
          .style('opacity', .8)
          .html(`${d.data.name}<br><span>${d.data.city}</span>`);

        let widthTooltip = parseInt(tooltip.style('width'));
        let heightTooltip = parseInt(tooltip.style('height'));

        tooltip
          .style('left', (d.data.projectionX - (widthTooltip / 2)) + 'px')
          .style('top', (d.data.projectionY - (heightTooltip * 1.5)) + 'px');

        let circles = d3.select('.map__circles')
          .selectAll('.map__point');

        circles
          .classed('is-selected', false);

        let circle = circles.filter(t => t.id === d.data.id);

        circle
          .classed('is-selected', true);
      }  
    });

  });
}

