import * as d3 from 'd3';

function compose(template, container, callback, progressCallback, replace = true) {

  d3.html(template)
    .get()
    .on('load', function(fragment) {
      let containerEl = document.querySelector(container);

      if (replace) {
        while (containerEl.firstChild) {
          let fragmentContent = containerEl.firstChild;
          let fragmentContentClone = containerEl.firstChild.cloneNode();

          // Should remove all event handlers
          // from previuous fragment
          containerEl.replaceChild(fragmentContentClone, fragmentContent);

          // Empty the container
          containerEl.removeChild(containerEl.firstChild);
        }
      }

      console.log(containerEl)

      containerEl.appendChild(fragment);

      // After rendering
      if (typeof callback === 'function') {
        // Execute callback passing the fragment back
        callback(d3.select(container));
      }
    })
    .on('progress', function(e) {
      // On progress
      if (typeof progressCallback === 'function') {
        // Execute callback passing the fragment back
        progressCallback(e);
      }
    });

}

export default compose;
