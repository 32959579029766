import page from 'page';

// Config
import firebaseConfig from './config/firebase.config';

// Components
import home from './components/home';
import login from './components/login';
import logout from './components/logout';

import about from './components/about';
import glossario from './components/glossario';
import bibliografia from './components/bibliografia';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let scenes = [];

// Page routes
page('/', () => home(scenes));
page('/about', () => about(scenes));
page('/glossario', () => glossario(scenes));
page('/bibliografia', () => bibliografia(scenes));
page('/login', login);
page('/logout', logout);
page();
