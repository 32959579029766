import * as d3 from 'd3';

function startProgress(e) {
  let progress = 0;
  let i = d3.interpolate(progress, e.loaded / e.total);

  d3.transition().tween('progress', function() {
    return function(t) {
      progress = i(t) * 100;
      d3.select('#progress').style('width', `${progress}%`);
    };
  });
}

function endProgress() {
  d3.select('#progress').transition().delay(250).style('width', 0);
}

export {startProgress, endProgress};
