import * as d3 from 'd3';
import page from 'page';

// Lib
import compose from '../lib/compose';

export default function() {

  compose('/components/login.html', '#app', (selection) => {

    let loginForm = d3.select('[data-action="login"]');

    loginForm.on('submit', function() {
      d3.event.preventDefault();
      let email = d3.select(this).select('[name="email"]').node().value;
      let password = d3.select(this).select('[name="password"]').node().value;

      login(email, password);
    });
 
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        page.redirect('/');
      } else {
        // No user is signed in.
      }
    });

  });
}

function login(email, password) {
  firebase.auth().signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      // var errorCode = error.code;
      var errorMessage = error.message;

      d3.select('[data-target="message"]').text(errorMessage);
    });
}
