import compose from '../lib/compose';
import destroyScenes from '../lib/destroy-scenes';
import { startProgress, endProgress } from '../lib/progress';

import TweenMax from 'gsap';
import 'gsap/src/uncompressed/plugins/ScrollToPlugin.js';

export default function(scenes) {
  // Destroy previous Scrollmagic scenes
  destroyScenes(scenes);

  compose('/components/about.html', '#app',
    () => {
      endProgress();
      TweenMax.to(window, 0, {scrollTo: '#about'});
    },
    function(e) {
      startProgress(e);
    }
  );
}
