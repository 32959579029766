import * as d3 from 'd3';
import page from 'page';
import Flickity from 'flickity-imagesloaded';
import retinajs from 'retinajs';

import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import TweenMax from 'gsap';
import 'gsap/src/uncompressed/plugins/ScrollToPlugin.js';

// Lib
import compose from '../lib/compose';
import destroyScenes from '../lib/destroy-scenes';
import { startProgress, endProgress } from '../lib/progress';

// Components
import drawMap from './map.js';

export default function(scenes) {

  firebase.auth().onAuthStateChanged(function(user) {
    if (true) {

      d3.json('/data/content.json', function(error, data) {
        if (error) throw error;

        // Render components
        compose('/components/home.html', '#app', 
          function(selection) {
            TweenMax.to(window, 0, {scrollTo: '#home-landing'});

            endProgress();
            // Destroy previous Scrollmagic scenes
            destroyScenes(scenes);

            // Initialize ScrollMagic controller
            let controller = new ScrollMagic.Controller({ loglevel: 0 });

            drawTimeline(selection);
            drawCarousel(selection);
            audioPlayer(selection);
            videoPlayer(selection);
            menuMobile(selection);

            drawMap();
            drawScrollMenu();
            scrollToChap();

            // Add ai2html elements
            compose('/assets/svg/schema_dsm.html', '#quattro__dsm');

            // Scrollmagic interaction

            // snapIntro(controller);
            pinScrollMenu(controller, scenes);
            manageScrollMenu(controller, scenes);
            toggleNavbars(controller, scenes);
            lazyImages(controller, scenes);
            pinTimelines(controller, scenes);
            landingInteractions(controller, scenes);
          },
          function(e) {
            startProgress(e);
          }
        );

      });

    } else {
      // No user is signed in.
      page.redirect('/login');
    }
  });
}

function menuMobile(selection) {
  let button = selection.select('[data-action="menu-mobile"]');

  button.on('click', function() {
    if(this.dataset.status === 'active') {
      d3.select(this).attr('data-status', '');

      d3.select(this).select('.bar').classed('animate', false);
      selection.select('.home__navigation_mobile').style('display', 'none');
    } else {
      d3.select(this).attr('data-status', 'active');

      d3.select(this).select('.bar').classed('animate', true);
      selection.select('.home__navigation_mobile').style('display', 'block');
    }
  });
}

function scrollToChap() {
  document.getElementById('landing-video')
    .addEventListener('ended', scroll, false);

  function scroll() {
    TweenMax.to(window, 2, {scrollTo: '#intro'});
  }
}

function landingInteractions(controller, scenes) {
  let scene = new ScrollMagic.Scene({
    triggerElement: '#home-landing',
    triggerHook: .3,
    offset: document.getElementById('intro').offsetHeight,
    reverse: true
  })
  .on('enter', function() {
    if (document.getElementById('landing-video')) {
      document.getElementById('landing-video').pause();
    }
  })
  .on('leave', function() {
    if (document.getElementById('landing-video')) {
      document.getElementById('landing-video').play();
    }
  });

  controller.addScene(scene);
  scenes.push(scene);

  // Mask buttons
  let play = d3.select('#mask-play-button');
  let scroll = d3.select('#mask-arrow-button');
  let video = d3.select('#landing-video').node();

  play.on('click', function() {
    d3.select('.trailer__mask')
      .transition()
      .duration(2000)
      .style('opacity', 0);

    d3.select('.trailer__mask')
      .transition()
      .delay(2000)
      .style('display', 'none');
    
    video.currentTime = 0;
    video.muted = false;
  });
    
  scroll.on('click', function() {
    TweenMax.to(window, 1, {scrollTo: '#intro'});
  });
  
}

function drawTimeline(selection) {
  let carousel = selection.select('[data-type="timeline"]').nodes();

  carousel.forEach(item => {
    var teamCarousel = new Flickity(item, {
      cellAlign: 'left',
      contain: true,
      adaptiveHeight: true,
      imagesLoaded: true,
      freeScroll: true,
      freeScrollFriction: 0.03
    });
  });
}

function drawCarousel(selection) {

  let carousel = selection.selectAll('[data-type="slideshow"]').nodes();

  carousel.forEach(item => {
    var teamCarousel = new Flickity(item, {
      cellAlign: 'left',
      contain: true,
      adaptiveHeight: true,
      imagesLoaded: true,
      lazyLoad: true,
      wrapAround: true,
      autoPlay: true
    });

    var caption = document.querySelector(`.slideshow__caption[data-index="${item.dataset.index}"]`);

    teamCarousel.on( 'select', function() {
      // set image caption using img's alt
      caption.textContent = teamCarousel.selectedElement.dataset.dida;
    });

  });
}

function videoPlayer(selection) {
  let containers = selection.selectAll('[data-type="video"]').nodes();

  containers.forEach(function(container) {

    let video = d3.select(container).select('video');

    d3.select(container)
      .select('button')
      .on('click', function() {

        if (container.dataset.action === 'is-playing') {
          video.node().pause();
          d3.select(container)
            .attr('data-action', '');

          d3.select(this)
            .transition()
            .style('opacity', 1);
        } else {

          video.node().play();

          d3.select(container)
            .attr('data-action', 'is-playing');

          d3.select(this)
            .transition()
            .style('opacity', 0);
        }
      });
  });
}

function audioPlayer(selection) {
  let players = selection.selectAll('[data-type="audio-player"]').nodes();
  let progresses = selection.selectAll('[data-type="audio-progress"]').nodes();
  let backButtons = selection.selectAll('[data-type="audio-button-back"]').nodes();
  let audioLengths = selection.selectAll('[data-type="audio-length"]').nodes();
  let buttons = selection.selectAll('[data-type="audio-button"]').nodes();

  buttons.forEach(button => {

    let id = button.dataset.playerid;
    let player = players.filter(player => player.dataset.playerid === id);
    let progress = progresses.filter(progress => progress.dataset.playerid === id);
    let backButton = backButtons.filter(backButton => backButton.dataset.playerid === id);
    let audioLength = audioLengths.filter(audioLength => audioLength.dataset.playerid === id);
    let duration = 0;


    player[0].addEventListener('loadstart', function() {

      d3.select(player[0]).on('timeupdate', function() {
        d3.select(progress[0])
          .transition()
          .style('width', (this.currentTime / duration * 100) + '%');

        drawPositionValue(this);
      });

      d3.select(button).on('click', function() {
        d3.event.preventDefault();

        if ( player[0].paused ) {
          d3.select(this).classed('is-playing', true);
          player[0].play();
        } else {
          d3.select(this).classed('is-playing', false);
          player[0].pause();
        }
      });

      d3.select(backButton[0]).on('click', function() {
        d3.event.preventDefault();
        let currentTime = player[0].currentTime;
        player[0].currentTime = currentTime - 10;
      });
    });


    player[0].addEventListener('loadedmetadata', function() {

      duration = player[0].duration;

      drawPositionValue(this);
    });

    function drawPositionValue(elem) {
      let date = new Date(null);
      date.setSeconds(Math.abs(elem.currentTime - duration));

      d3.select(audioLength[0]).text('- ' + d3.timeFormat('%M:%S')(date));
    }
  });
}

function drawScrollMenu() {
  let bodyHeight = document.getElementById('capitolo-5').getBoundingClientRect().bottom;
  let menuHeight = 500;

  let container = d3.select('#scroll-menu ul');
  let sections = d3.selectAll('[data-section]');

  let y = d3.scaleLinear()
    .domain([0, bodyHeight])
    .range([0, menuHeight]);

  //set scroll-menu position relative to viewport
  d3.select('#scroll-menu')
    .style('right', - ((window.innerWidth / 2) - 512 - 48) + 'px');

  sections.each(function() {
    let section = d3.select(this);
    let articles = section.selectAll('[data-article]');

    let sectionPoint = document.createElement('div');
    let sectionMark = document.createElement('li');

    sectionMark.appendChild(sectionPoint);
    sectionPoint.setAttribute('class', 'scroll-menu__section');
    sectionPoint.setAttribute('data-section-index', this.dataset.section);

    let articlesContainer = document.createElement('ul');
    
    articles.each(function() {
      let articlePoint = document.createElement('div');
      let articleMark = document.createElement('li');

      articleMark.appendChild(articlePoint);
      articlePoint.setAttribute('class', 'scroll-menu__article');
      articlePoint.setAttribute('data-article-index', this.dataset.article);

      let articleHeight = this.offsetHeight;

      articleMark.setAttribute('style', `height: ${y(articleHeight)}px`);

      articlesContainer.appendChild(articleMark);
      sectionMark.appendChild(articlesContainer);
    });

    container.node().appendChild(sectionMark);
  });
}

function pinScrollMenu(controller, scenes) {
  let scene = new ScrollMagic.Scene({
    triggerElement: '#scroll-menu',
    triggerHook: 0,
    offset: -80,
    reverse: true,
  })
  //.addIndicators({name: 'scroll-menu'})
  .setPin('#scroll-menu');

  controller.addScene(scene);
  scenes.push(scene);
}

// function snapIntro(controller) {

//   var scene = new ScrollMagic.Scene({
//     triggerElement: '#intro',
//     triggerHook: 0,
//     offset: document.getElementById('intro').offsetHeight / 2,
//     reverse: true,
//   }).on('enter', function() {
//     controller.scrollTo('#capitolo-1');
//   });
//   //.addIndicators({name: 'intro'});

//   controller.addScene(scene);
// }

function toggleNavbars(controller, scenes) {

  let scene = new ScrollMagic.Scene({
    triggerElement: '#home-landing',
    triggerHook: 0,
    offset: document.getElementById('home-landing').offsetHeight,
    reverse: true,
  })
  // .addIndicators({name: 'main navigation'})
  .on('enter', function() {
    TweenMax.to('#navigation-main', .25, { autoAlpha: 0, height: 0 });
    TweenMax.to('#navigation-sub', .25, { autoAlpha: 1, height: 'auto' });
  })
  .on('leave', function() {
    TweenMax.to('#navigation-main', .25, { autoAlpha: 1, height: 'auto' });
    TweenMax.to('#navigation-sub', .25, { autoAlpha: 0, height: 0 });
  });

  controller.addScene(scene);
  scenes.push(scene);
}

function manageScrollMenu(controller, scenes) {
  let sections = d3.selectAll('[data-section]');
  let menuSections = d3.selectAll('[data-section-index]');
  let articles = d3.selectAll('[data-article]');
  let menuHeight = 520;

  menuSections.on('click', function() {

    // show hide menu on mobile devices
    if (this.dataset.device === 'mobile') {
      d3.select('.home__navigation_mobile').style('display', 'none');
      d3.select('[data-action="menu-mobile"]').attr('data-status', '');
      d3.select('[data-action="menu-mobile"]').select('.bar').attr('class', 'bar');
    }

    // change behaviour of controller to animate scroll instead of jump
    controller.scrollTo(function (newpos) {
      TweenMax.to(window, 0.5, { scrollTo: { y: newpos, autoKill: false }});
    });

    controller.scrollTo(`#capitolo-${this.dataset.sectionIndex}`);
  });

  let currentSection;
  let currentSectionPoint;

  let currentArticle;
  let currentArticlePoint;

  let y = d3.scaleLinear()
    .domain([0, 1])
    .range([0, menuHeight]);

  // Watches sections
  sections.each(function() {
    let elId = this.id;
    let section = this.dataset.section;

    let sectionScene = new ScrollMagic.Scene({
      triggerElement: `#${elId}`,
      triggerHook: 0,
      offset: 0,
      duration: document.getElementById(elId).offsetHeight
    })
    // .addIndicators({name: `section ${section}`})
    .on('enter', function(e) {
      currentSection = e.target.triggerElement().dataset.section;
      currentSectionPoint = d3.select(`[data-section-index="${currentSection}"]`);

      d3.selectAll('[data-section-index]').classed('is-active', false);

      currentSectionPoint.classed('is-active', true);

    })
    .on('leave', function() {
      // d3.selectAll('[data-section-index]').each(function() {
      //   d3.select(this).classed('is-active', false);
      // });

      // currentSectionPoint.classed('is-active', true);
    });

    controller.addScene(sectionScene);
    scenes.push(sectionScene);
  });

  // Watches articles
  articles.each(function() {
    let elId = this.id;
    let article = this.dataset.article;

    let articleScene = new ScrollMagic.Scene({
      triggerElement: `#${elId}`,
      triggerHook: 0,
      offset: 0,
      duration: document.getElementById(elId).offsetHeight / 6
    })
    // .addIndicators({name: `article ${article}`})
    .on('enter', function(e) {
      currentArticle = e.target.triggerElement().dataset.article;
      currentArticlePoint = d3.select(`[data-article-index="${currentArticle}"]`);

      currentArticlePoint.classed('is-active', true);
    })
    .on('leave', function() {
      d3.selectAll('[data-article-index]').each(function() {
        d3.select(this).classed('is-active', false);
      });

      currentArticlePoint.classed('is-active', true);
    });

    controller.addScene(articleScene);
    scenes.push(articleScene);
  });

  // Draw progress line
  new ScrollMagic.Scene({
    triggerElement: '#capitolo-1',
    triggerHook: 1,
    reverse: true,
    duration: document.getElementById('capitolo-5').getBoundingClientRect().bottom
  }).on('progress', function(e) {
    if (currentSectionPoint) {
      // let index = currentSectionPoint.node().dataset.sectionIndex - 1;
      d3.select('#scroll-menu-progress').style('height', `${y(e.progress)}px`);
    }
  });
  //.addIndicators({name: 'progress'})
  //.addTo(controller);
}

function lazyImages(controller, scenes) {
  const images = d3.selectAll('[data-src]');

  images.each(function() {
    let trigger = `[data-src="${this.dataset.src}"]`;

    let scene = new ScrollMagic.Scene({
      triggerElement: trigger,
      triggerHook: 0,
      offset: - window.innerHeight,
      reverse: false
    })
    .on('enter', function(e) {
      let re = /(?:\.([^.]+))?$/;
      let currentImage = e.target.triggerElement().dataset.src;
      let ext = re.exec(currentImage);
      let image = document.createElement('img');
      image.setAttribute('src', currentImage);
      image.setAttribute('data-rjs', 2);
      image.setAttribute('style', 'opacity: 0');

      e.target.triggerElement().appendChild(image);

      if (ext[1] !== 'svg' && document.querySelector(`[src="${currentImage}"]`))

        window.addEventListener('load', function() {
          retinajs([document.querySelector(`[src="${currentImage}"]`)]);
        });

      TweenMax.to(`${trigger} img`, 1, { autoAlpha: 1 });
    });

    controller.addScene(scene);
    scenes.push(scene);
  });
}

function pinTimelines(controller, scenes) {
  const timelines = d3.selectAll('[data-type="timeline"]');

  timelines.each(function() {
    let section = `[data-section="${this.dataset.section}"]`;

    let scene = new ScrollMagic.Scene({
      triggerElement: section,
      triggerHook: 0,
      duration: document.querySelector(section).offsetHeight / 2,
      reverse: true
    })
    .setPin(section);

    controller.addScene(scene);
    scenes.push(scene);
  });
}
